@tailwind base;
@tailwind components;
@tailwind utilities;

.triangle-background {
    background-image: url('../public/img/patterns/triangularPattern.webp'); /* Adjust the path based on your file structure */
    background-repeat: repeat; /* Ensure the pattern repeats */
    background-size: 500px;
    
  }

  .remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.remove-arrow {
    -moz-appearance: textfield;
}

.prevent-select {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@font-face {
  font-family: 'helvetica';
  src: '/fonts/helvetica.ttf' format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}
.font-helvetica{
  font-family: 'helvetica', sans-serif;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}


@font-face {
  font-family: inter;
  src: '/fonts/Inter.ttf' format('truetype');
  font-weight: 1 999; /* Define font weight range */
  font-style: normal;
  font-display: fallback;
}

::selection{
  background-color: #242424; /* Set the background color */
  color: #ffffff; /* Set the text color */
}

.font-inter {
  font-family: inter, sans-serif;
}

.font-inter-semibold {
  font-family: inter semibold, sans-serif;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@layer base {
  .font-outline-2 {
    -webkit-text-stroke: 1px rgb(44, 44, 44);
  }
  .font-outline-4 {
    -webkit-text-stroke: 2px rgb(44, 44, 44);
  }
  .font-outline-2-w {
    -webkit-text-stroke: 1px white;
  }
  .font-outline-4-w {
    -webkit-text-stroke: 2px white;
  }
}